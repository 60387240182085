<template>
  <div class="pb-2">
    <div v-if="this.loadpage == false" class="text-center pt-3">
      <b-spinner
        style="width: 5rem; height: 5rem;"
        variant="primary"
        label="Large Spinner"
      />
    </div>
    <b-card v-if="this.loadpage == true" no-body>
      <b-card-body class="pb-0">
        <b-row>
          <b-col class="col-12 pb-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0">วันที่: </span>
              <flat-pickr v-model="date" class="form-control col-md-2" :config="datePickerConfig"/>
              <div class="mx-1">-</div>
              <flat-pickr v-model="dueDate" class="form-control col-md-2" :config="datePickerConfig"/>
            </div>
          </b-col>
          <b-col class="col-12 pb-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0">เลขออเดอร์: </span>
              <b-form-input v-model="orderDtlsId" class="col-md-2"></b-form-input>
            </div>
          </b-col>
          <b-col class="col-12 pb-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0">สถานะ: </span>
              <b-form-radio-group
                v-model="statusId"
                :options="statusList"
                text-field="text"
              >
              </b-form-radio-group>
            </div>
          </b-col>
          <b-col class="col-12 pt-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0"></span>
              <b-button variant="primary" @click="getOrder()">
                ค้นหา
              </b-button>
            </div>
          </b-col>
        </b-row>
        <hr class="mt-2">
      </b-card-body>

      <b-card-body>
        <div v-if="this.loadtable == false" class="text-center pt-3">
          <b-spinner
            style="width: 5rem; height: 5rem;"
            variant="primary"
            label="Large Spinner"
          />
        </div>
        <div v-if="this.loadtable == true">
          <div class="d-flex justify-content-between pb-1">
            <div>ยอดที่ยังค้างชำระ : {{format_price(this.dept)}} บาท</div>
            <div>จำนวนทั้งหมด : {{this.totalRows}}</div>
          </div>
          <b-table
            :items="orderList"
            :fields="orderFields"
            responsive
            class="position-relative text-nowrap"
          >
            <template #cell(quantity)="data">
              <div>{{format_price(data.value)}}</div>
            </template>
            <template #cell(unitPrice)="data">
              <div>{{format_price(data.value)}}</div>
            </template>
            <template #cell(total)="data">
              <div>{{format_price(data.value)}}</div>
            </template>
            <template #cell(paid)="data">
              <div>{{data.item.paid == null ? '' : format_price(data.value)}}</div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTabs, BTab, BSpinner, BTable, BPagination, BButton, BLink, BRow, BCol,
  BFormRadioGroup, BFormInput
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import { header_token } from '@/views/token'
import { format_price } from '@/views/global_function.js'
import {jjCargoService_dotnet} from '@/views/global.js'
export default {
  name: 'JjcargoFrontendOrder',

  components: {
    BCard, BCardHeader, BCardBody, BTabs, BTab, BSpinner, BTable, BPagination, BButton, BLink, BRow, BCol,
    BFormRadioGroup, BFormInput,
    flatPickr,
  },

  data() {
    return {
      orderDtlsId: '',
      date: null,
      dueDate: null,
      datePickerConfig: {
        altInput: true,
        altFormat: "d-m-Y",
        altLanguage: "th"
      },
      statusId: 0,
      statusList: [
        {text: 'ทั้งหมด', value: 0},
        {text: 'ยังไม่จ่าย', value: 1},
        {text: 'จ่ายบางส่วน', value: 2},
        {text: 'จ่ายแล้ว', value: 3},
      ],

      dept: null,
      orderList: [],
      orderFields: [
        { key: 'orderDtlsID', label: 'OrderID', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'receiveDate', label: 'วันที่รับสินค้า', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'styleName', label: 'ชื่อสินค้า', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'productID', label: 'รหัสสินค้า', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'quantity', label: 'จำนวน', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'unitPrice', label: 'ราคา', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'total', label: 'ยอดรวม', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'paid', label: 'จ่ายแล้ว', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'statusName', label: 'สถานะ', thClass: 'text-center', tdClass: 'text-center' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,

      loadpage: true,
      loadtable: true
    };
  },

  async created() {
    this.loadpage = false
    await this.getOrder()
    this.loadpage = true
  },

  watch: {
    async currentPage() {
      await this.getOrder()
    }
  },

  methods: {
    format_price,
    async getOrder() {
      this.loadtable = false
      var skip = 0
      skip = (this.currentPage - 1) * 10
      let response = await axios.post(
        jjCargoService_dotnet + 'Order/get-order-detail',
        {
          "customerID": JSON.parse(localStorage.getItem('cusId')),
          "orderDtlsID": this.orderDtlsId == "" ? null : this.orderDtlsId,
          "startDate": this.orderDtlsId != "" ? null : this.date,
          "endDate": this.orderDtlsId != "" ? null : this.dueDate,
          "statusID": this.statusId,
          "skip": skip,
          "take": 10
        },
        { headers: header_token }
      );
      this.orderList = response.data.orderList
      this.totalRows = response.data.totalRows
      this.date = response.data.startDate
      this.dueDate = response.data.endDate
      this.dept = response.data.deptBalance
      this.loadtable = true
    },

    // toOrderDetail(id) {
    //   window.open('/order-detail?orderId=' + id);
    // },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
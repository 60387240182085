const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const shipmentcode = urlParams.get('num');
// const orderId = urlParams.get('orderId');
export default [
  //new route
  //shipment
  {
      path: '/product-status',
      name: 'product-status',
      component: () => import('@/views/shipment/product-status.vue'),
      meta: {
        pageTitle: 'สถานะสินค้า',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'สถานะสินค้า',
            active: true,
          },
        ],
        resource: 'normal',
        action: 'manage',
      },
  },
  {
    path: '/shipment-detail',
    name: 'shipment-detail',
    component: () => import('@/views/shipment/shipment-detail.vue'),
    meta: {
      pageTitle: 'รายละเอียด',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'รายละเอียด',
          active: false,
          to: 'product-status',
        },
        {
          text: shipmentcode,
          active: true,
        },
      ],
      resource: 'normal',
      action: 'manage',
    },
  },

  //balance
  {
    path: '/finance-balance',
    name: 'finance-balance',
    component: () => import('@/views/balance/balance.vue'),
    meta: {
      pageTitle: 'รายการ Balance',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'รายการ Balance',
          active: true,
        },
      ],
      resource: 'normal',
      action: 'manage',
    },
  },

  //order
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/order.vue'),
    meta: {
      pageTitle: 'ออเดอร์',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ออเดอร์',
          active: true,
        },
      ],
      resource: 'normal',
      action: 'manage',
    },
  },
  // {
  //   path: '/order-detail',
  //   name: 'order-detail',
  //   component: () => import('@/views/order/order-detail.vue'),
  //   meta: {
  //     pageTitle: 'รายละเอียดออเดอร์',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'รายละเอียดออเดอร์',
  //         active: false,
  //         to: 'order',
  //       },
  //       {
  //         text: orderId,
  //         active: true,
  //       },
  //     ],
  //     resource: 'normal',
  //     action: 'manage',
  //   },
  // },
  {
    path: '/order-overdue',
    name: 'order-overdue',
    component: () => import('@/views/order/order-overdue.vue'),
    meta: {
      pageTitle: 'ออเดอร์ค้างชำระ',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ออเดอร์ค้างชำระ',
          active: true,
        },
      ],
      resource: 'normal',
      action: 'manage',
    },
  },

  //file test
  {
    path: '/JJCargo/test',
    name: 'JJCargo-test',
    component: () => import('@/views/test.vue'),
    meta: {
      pageTitle: 'test',
      breadcrumb: [
        {
          text: 'test',
          active: true,
        },
      ],
      resource: 'normal',
      action: 'manage',
    },
  },
]
export default [
    // {
    //   header: '—— การจัดส่ง ——',
    //   resource: 'normal',
    //   action: 'manage',
    // },
    {
      title: 'ออเดอร์',
      route: 'order',
      icon: 'FileTextIcon',
      resource: 'normal',
      action: 'manage',
    },
    {
      title: 'ออเดอร์ค้างชำระ',
      route: 'order-overdue',
      icon: 'FileTextIcon',
      resource: 'normal',
      action: 'manage',
    },

  ]